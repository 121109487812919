












































































































































































import { ErrorManager } from '@/models/error';
import { FormRules } from '@/utils/formRules';
import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'ResetPassword',
  mixins: [FormRules],
  data() {
    return {
      show: false,
      showPassword: false,
      dialog: false,
      password1: '',
      password2: '',
      errorMessage: '',
      token: '',
      userId: '',
      urlToken: '',
    };
  },

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    disableBtn(): boolean {
      return (
        this.password1 == this.password2 &&
        this.password1 != '' &&
        this.password2 != ''
      );
    },
  },

  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    goToSignUpPage() {
      this.$router.push({ name: 'sign-up' });
    },
    goToForgetPasswordPage() {
      this.$router.push({ name: 'forget-password' });
    },
    newPassword() {
      const payload = {
        new_password1: this.password1,
        new_password2: this.password2,
        uid: this.userId,
        token: this.token,
        urlToken: this.urlToken,
      };

      this.resetPassword(payload)
        .then((data: any) => {
          this.dialog = true;
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
    },
  },
  mounted() {
    this.show = true;
    this.urlToken = this.$route.params.token;
    this.userId = this.urlToken.substring(0, this.urlToken.indexOf('-'));
    this.token = this.urlToken.substring(
      this.urlToken.indexOf('-') + 1,
      this.urlToken.length
    );
  },
});
