






import Vue from 'vue'
import ResetPassword from '../../components/auth/ResetPassword.vue'
export default Vue.extend({
  name: "AuthResetPassword",
  components: {
      ResetPassword
  },
  metaInfo(){
      return  {
      title: "Reset Password - Our Nation Express",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Welome to Our Nation Express. Click the link to reset password."
        }
      ]
    };
    }
    
})
